<template>
  <div class="iss-main-grid">
    <!--    数据总览  -->
    <a-row :gutter="16" class="mb-16 rowClass">
      <a-col :span="8">
        <div class="statistics-card" flex-attr="task">
          <div class="statistics-title">
            <div class="mun fs-30 ">
              {{ overviewData.total ? overviewData.total > 0? overviewData.total/ 100 : overviewData.total : 0}}
              <span class="yuan">元</span>
            </div>
            <div class="fontStyle">
              平台总营收
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="statistics-card" flex-attr="user">
          <div class="statistics-title">
            <div class="mun fs-30">
              {{ overviewData.subscribe ? overviewData.subscribe > 0? overviewData.subscribe/ 100 : overviewData.subscribe : 0}}
              <span class="yuan">元</span>
            </div>
            <div class="fontStyle">
              订阅总营收
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="statistics-card" flex-attr="company">
          <div class="statistics-title">
            <div class="mun fs-30">
              {{ overviewData.gold ? overviewData.gold > 0? overviewData.gold/ 100 : overviewData.gold : 0}}
              <span class="yuan">元</span>
            </div>
            <div class="fontStyle">
              金币总营收
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
    </a-row>

    <!--     筛选区域  -->
<!--    <div class="statistics-card mb-16">-->
<!--      -->
<!--    </div>-->

    <!--     分页 -->
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #payStatus="{ text }">
          <a-tag v-if="text === 2" color="success">支付成功</a-tag>
          <a-tag v-else-if="text === 3" color="error">支付失败</a-tag>
          <a-tag v-else-if="text === 1" color="warning">未支付</a-tag>
          <a-tag v v-else-if="text === 4" color="default">支付关闭</a-tag>
        </template>

        <template #packageType="{ record }">
          <div v-if="record.packageType === 0">订阅套餐</div>
          <div v-if="record.packageType === 1">金币充值</div>
        </template>

        <template #payerTotal="{ record }">
          <div v-if="record.payerTotal">
            {{record.payerTotal > 0 ? record.payerTotal/100 : record.payerTotal}}
          </div>
          <div v-else>0</div>
        </template>

        <template #paymentType="{ record }">
          <div v-if="record.paymentType === 'wx'">微信</div>
          <div v-if="record.paymentType === 'zfb'">支付宝</div>
          <div v-if="record.paymentType === 'ApplePay'">苹果支付</div>
        </template>

        <template #avatar="{ record }">
          <div  @click="handleName(record)" class="cursor-p" >
            <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
            <a-avatar :size="30" v-else>
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </template>

        <template #userName="{ record }">
          <div @click="handleName(record)" class="cursor-p" v-if="record.userName">{{record.userName}}</div>
          <div @click="handleName(record)" class="cursor-p" v-else>暂无</div>
        </template>

        <template #orderNo="{ record }">
         <div>{{record.orderNo}}</div>
          <div
              class="cursor-p activeColor"
              v-if="record.orderNo"
              @click="getValue(record.orderNo)"
          >
            复制
          </div>
        </template>

        <template #operation="{ record }">
          <operation :options="options" :record="record">
            <template #goDetail>
              <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
            </template>
            <template #more>
              <div class="primary-text cursor-p">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click.prevent> 更多操作 </a>
                  <template #overlay>
                    <a-menu @click="clickOptions($event, record)">
                      <a-menu-item
                          v-for="item in itemOption"
                          :key="item.value"
                      >
                        <span class="padding-10">{{ item.label }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </template>
          </operation>
        </template>
      </grid>
    </div>
  </div>
  <recharge-view
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleView"
  />
</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
import {Row, Col, Tag, Modal, Menu, Dropdown, Avatar, message,} from 'ant-design-vue';
import {
  UserOutlined,
  FileTextOutlined,
} from '@ant-design/icons-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import ordersApi from "@/api/orders.js";
import {useRouter} from "vue-router";
import {downloadExcel, setAvatar, copyText} from "@/utils";
import rechargeView from "@/views/user/components/rechargeView";
import SearchForm from '@/components/searchForm';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    // ARangePicker: DatePicker.RangePicker,
    // ASpace: Space,
    FileTextOutlined,
    Operation,
    Grid,
    rechargeView,
    SearchForm,
    ATag: Tag,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ADropdown: Dropdown,
    AAvatar: Avatar,
    UserOutlined,
  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      activeItem: {},
      overviewData: {},

    });

    const clickOptions = ({ key }, record) => {
      console.log('更多操作', key, record);
      switch (key) {
        case 'order':
          router.push(`/user/${record.userId}?activeKey=3`)
          break;
        case 'invitation':
          router.push(`/user/${record.userId}?activeKey=2`)
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要封禁该账号吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
      }
    };
    ordersApi.getPayOverview('', {}).then(res => {
      if (res) {
        state.overviewData = res

      }
    });


    return {
      setAvatar,
      gridRef,
      ...toRefs(state),
      itemOption: [
        { value: 'order', label: 'ta的订单' },
        { value: 'invitation', label: 'ta的邀请' },
        { value: 'disable', label: '封禁账号' },
      ],
      items: [
        {
          key: 'paymentType',
          label: '支付方式',
          type: 'select',
          dataset: [
            { label: '微信', value: 'wx' },
            { label: '支付宝', value: 'zfb' },
            { label: '苹果支付', value: 'ApplePay' },
          ],
        },
        {
          key: 'packageType',
          label: '支付类型',
          type: 'select',
          dataset: [
            { label: '订阅套餐', value: 0 },
            { label: '金币充值', value: 1 },
          ],
        },
        //   todo 支付项目
        {
          key: 'packageId',
          label: '支付项目',
          type: 'select',
          valueKey: 'id',
          labelKey: 'packageName',
          url: `${ordersApi.getPackageInfoTypeList}`,
          linkedKey: 'city',
        },

        {
          key: 'payStatus',
          label: '订单状态',
          type: 'select',
          dataset: [
            { label: '未支付', value: 1 },
            { label: '支付成功', value: 2 },
            { label: '支付失败', value: 3 },
            { label: '支付关闭', value: 4 },
          ],
        },

        //  需要补上日期时间筛选
        {
          key: 'date',
          timeKey: ['startCreateTime', 'endCreateTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        {
          key: 'content',
          label: '昵称或订单号',
          type: 'input',
        },

      ],
      columns: [
        { dataIndex: 'avatar', title: '头像', width: 50, ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true,
          slots: { customRender: 'userName' },
        },
        { dataIndex: 'packageTitle', title: '订单名称', width: 150, ellipsis: true },
        { dataIndex: 'orderNo', title: '订单号', width: 250, ellipsis: true,
          slots: { customRender: 'orderNo' },
        },
        { dataIndex: 'payerTotal', title: '订单金额', width: 80, ellipsis: true,
          slots: { customRender: 'payerTotal' },
        },
        { dataIndex: 'createTime', title: '订单创建时间', width: 150, ellipsis: true },
        {
          dataIndex: 'packageType',
          title: '支付类型',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'packageType' },
        },
        { dataIndex: 'packageName', title: '支付项目', width: 120, ellipsis: true,
          slots: { customRender: 'packageName' },
        },
        { dataIndex: 'paymentType', title: '支付方式', width: 80, ellipsis: true,
          slots: { customRender: 'paymentType' },
        },
        { dataIndex: 'payStatus', title: '订单状态', width: 120, ellipsis: true,
          slots: { customRender: 'payStatus' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            ordersApi
                  .export('contact:export', {})
                .then(({ name, data }) => downloadExcel(name, data));
                  // .then((data) => downloadExcel('用户充值数据.xlsx', data));
          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: (ids) => {
            if (ids.length) {
              ordersApi
                  .export('contact:export',{ idList:[...ids]})
                  .then(({ name, data }) => downloadExcel(name, data));
            }else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
    options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: ({ id }) => {
            // router.push({
            //   name: '',
            //   query: {
            //     id,
            //   },
            // });
            console.log(id, router);
          },
        },
        // {
        //   type: 'more',
        //   slotName: 'more',
        //   title: '更多操作',
        //   permission: 'user:more',
        //   fnClick: ({ id }) => {
        //     console.log(id);
        //   },
        // },
      ],
      url: ordersApi.pageUrl,
      gridHeight: document.body.clientHeight - 240,
      clickOptions,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleName: (record) => {
        return router.push(`/user/${record.userId}`)
      },
      handleView: (value) => {
        state.visible = value

      },
      handleGotoView: ($event, record)=> {
        // 查询金币流水
        ordersApi.orderInfo('', record.id).then(res => {
          if (res) {
            state.activeItem = res
            // console.log('dddd', state.activeItem)
          }
        });
        state.visible = true
      },

      getValue(value) {
        copyText(value);
        message.success('复制成功！');
      },
    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
.yuan {
  font-size: 14px;
}


</style>